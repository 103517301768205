.App {
  text-align: center;
  font-family: sans-serif;
  height: 100vh;
  width: 100vw;
  background:radial-gradient(circle, rgba(254,124,162,1) 38%, rgba(255,50,0,1) 100%)

}


